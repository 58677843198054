:root {
  --primary-color: #6d28d9;
  --table-header-bg: #683fe9;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #e6e3ff;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
  --home-liner-gradient: linear-gradient(99.9deg, #381d6d, #aa2dae);
  --side-bar-logo: 0;
  --side-bar-logo-display: "block";
  --uib-size: 40px;
  --uib-speed: 2.5s;
  --uib-color: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}
/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: collapse;
}
.rc-table-thead {
  border-top: 1px solid #d5d5d5a0;
  border-bottom: 1px solid #d5d5d5;
}
.rc-table-thead .rc-table-cell {
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: var(--font-size-14);
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
table .header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  body.fixed .header-fixed {
    display: none;
  }
}
.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}
/*
	 We are clearing the body's margin and padding, so that the header fits properly.
	 We are also adding a height to demonstrate the scrolling behavior. You can remove
	 these styles.
 */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: white !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid white !important;
}
.ant-menu:not(.ant-menu-inline) {
  color: white !important;
}
.divider-transition-header-left {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}

.main-divider-transition-header-left {
  width: 0%;
  height: 100%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}
.divider-transition-header-right {
  width: 100%;
  height: 100%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}

.main-divider-transition-header-right {
  width: 0%;
  height: 100%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
  transform: rotate(180deg);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 2px solid #3a6a01;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background: #3a6a01;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: "white";
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}
.ant-tabs-ink-bar {
  background: transparent !important;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}
.ant-tabs-tab {
  width: 150px;
  /* padding-left: 10px; */
  justify-content: center;
}
.ant-tabs-tab:hover {
  color: #3a6a01 !important;
}
/* home-font-icons */
.header-banner {
  cursor: pointer;
  font-size: 12px;
  /* margin-top: 1px; */
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: -4px;
  line-height: 0px !important;
  padding: 1px;
}
.vibrate-icon {
  margin-top: 3px !important;
}
.switch-button {
  /* background-color: #683fe9; */
  font-size: 3px !important;
  height: "2rem";
}
.social-media-icons {
  color: white;
  font-size: 10.78px;
}
.icon-modify {
  transform: translateY(-3px);
}
.footer_icon_list {
  /* width: 20px; */
  /* height: 20px; */
  /* margin-top: 4px; */
  /* padding: 1px; */
  /* justify-items: center; */
}
@media only screen and (max-width: 436px) and (min-width: 0px) {
  .footer_icon_list {
    margin-top: 5px;
  }
  .header-banner {
    margin-top: 4px;
  }
  .expendable-table {
    padding: 20px;
  }
  .high-cash-incentive-details-box {
    padding-right: 20px;
  }
  .gallery_third_image_size {
    width: 44vw !important;
    height: 28vw !important;
  }
}
@media only screen and (max-width: 770px) and (min-width: 0px) {
  .new-tag {
    width: 50px;
    position: absolute;
    filter: invert(1);
    margin-top: 29px;
  }
  .vigilince_text {
    font-size: 14px !important;
  }
  .ant-menu-submenu > .ant-menu {
    border-radius: 17px !important;
    max-width: 500px !important;
    width: 300px !important;
  }
  .ant-pagination-options {
    display: none !important;
  }
}
.font-icon {
  align-items: center;
}
.home_menu_bar {
  background: rgb(31 0 95 / 0%);
  color: white;
  border: 0px;
  width: 100%;
  font-family: Inter;
  gap: 1.2rem;
  font-weight: 600 !important;
  font-size: 0.8vw !important;
}

.css-view-1dbjc4n r-flex-13awgt0 {
  overflow-x: hidden;
}
@font-face {
  font-family: Nilavu;
  src: url(./assets/Font/TAU-Nilavu-2.woff);
}

@font-face {
  font-family: BebasNeue;
  src: url(./assets/Font/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: Inter;
  src: url(./assets/Font/Inter-VariableFont_slnt.ttf);
}
@font-face {
  font-family: Inters;
  src: url(./assets/Font/Inter-Black.ttf);
}
@font-face {
  font-family: Poppins;
  src: url(./assets/Font/Poppins-Regular.ttf);
}
@font-face {
  font-family: "Bebas Neue", cursive;
  src: url(./assets/Font/BebasNeue-Regular.ttf);
}
@font-face {
  font-family: Helvetica;
  src: url(./assets/Font/Helvetica.ttf);
}
/* slider */
/* .css-view-1dbjc4n {
  justify-content: center;
} */
.font_increase_icon {
  margin-left: -1px;
}

/* .css-text-901oao {
  padding-left: 2px;
} */
/* @media (min-width: 375px) {
  .css-text-901oao {
    transform: scale(1.5);
  }
} */
.service-left-icon {
  width: 60px;
  height: 60px;
  background-color: #e1e6ff;
  border: 0px solid black;
  border-radius: 50px;
  padding: 15px;
}
.FiArrowUpRight {
  position: absolute;
  /* transform: translate(135px,-28px); */
  margin-left: 190px;
  margin-top: -20px;
}
.sport-large-logo {
  height: 100%;
  z-index: -1;
  position: absolute;
}
.large-logo {
  position: sticky, absolute;
  top: 20px;
}
.AboutUsContents-background-image {
  width: 100%;
  /* position: absolute; */
  opacity: 0.6;
  /* background: var(--home-liner-gradient); */
}
.history-image {
  /* height: 200px; */
  height: 100%;
  width: 100%;
  border-radius: 12px;
  -webkit-filter: grayscale(90%);
}
.view-all-button {
  width: 100%;
  text-align: center;
}
.ant-image-img {
  /* width:; */
}
.executive-component-box {
  height: 400px;
  overflow-y: hidden;
  padding-left: 60px;
  padding-right: 60px;
}
.executive-component-box.active {
  height: 400px;
}
.executive-component-box.inactive {
  height: fit-content;
}
.vertace-logo {
  width: 70px;
  margin-left: 6px;
}
.awssld__content {
  background-color: rgba(255, 255, 255, 0) !important;
}
.awssld__bullets {
  display: none !important;
}
button.awssld__prev {
  display: none !important;
}
/* .awssld__controls button{
border: 1px solid black !important;  
border-radius: 50% !important;
width:8% !important;
height: 13% !important;
margin-right: 2%;
box-shadow: 0px 16.6154px 19.9385px rgba(0, 0, 0, 0.11) !important;
background: #FFFFFF !important;
color: #3B1B7E !important;
}
.span.awssld__controls__arrow-right{
  color: #3B1B7E !important;
} */
.awssld {
  --organic-arrow-height: 22px !important;
}
.table_active {
  visibility: visible;
}
.table_inactive {
  visibility: hidden;
}
.table_row {
  font-family: Inter;
  font-weight: 500;
  height: 60px;
  align-items: center;
}
.table_row_height {
  height: auto;
  transition: 0.9s ease;
}
.table_row_normal {
  height: 60px;
  max-height: 100px;
  transition: 0.9s;
}
.imagesbd {
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
.expendable-table {
  width: 96%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Inter";
}
.expendable-table-header {
  background-color: #eeeeee;
}
.expendable-table-contents > td {
  padding: 12px 15px !important;
}
.competition_name {
  cursor: pointer;
  color: #2f80ed;
  font-weight: 500;
  margin-bottom: 0px !important;
}
.description_contents {
  padding-right: 50px;
}
.hostel_lists {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 10px;
}
@media (max-width: 444px) {
  .hostel_lists {
    grid-template-columns: 100%;
  }
  .index-padding {
    padding: 30px !important;
    position: absolute;
  }
}
.stadium-img {
  border-radius: 34px;
}
.carousel {
  width: 100% !important;
  height: 150px !important;
  justify-content: center !important;
}
.icons-list {
  transform: scale(1.2);
  width: 20px;
}
.schemes-table {
  align-items: center;
}
.padding-change {
  padding: 10px 9px !important;
}
.padding-description-changes {
  padding-right: 35px !important;
  text-align: justify;
}
.table-list-padding-change {
  padding: 10px 0px 15px 10px !important;
}
.contact_us_card_image {
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  filter: drop-shadow(0px 14px 24px rgba(11, 46, 144, 0.1));
  object-fit: cover;
}
.contact-welfare-officer-box {
  padding: 20px;
  border: 1px solid black;
  filter: drop-shadow(0px 14px 24px rgba(11, 46, 144, 0.1));
}
.head-office-logo {
  border-radius: 20px;
  width: 40vw;
}
.gallery_box {
  display: grid;
  gap: 20px;
  grid-template-columns: 40% 20% 20% 20%;
}
.gallery_box1 {
  display: flex;
  flex-direction: row;
}
/* .gallery_box:first-child {
  width: 100px;
} */
.subject {
  color: #2f80ed;
}
.ant-select-arrow {
  color: black !important;
}
.ant-image-preview-operations {
  z-index: 3000 !important;
}
.ant-image-preview-img {
  z-index: 0 !important;
}
.gallery_second_image_size {
  width: 40vw !important;
  height: 30vw !important;
}
.slideshow {
  width: 100%;
}

.controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
}
.ant-pagination-options {
  /* position: absolute !important; */
  /* margin-left: 20vh !important; */
}
@media (min-width: 1440px) {
  .ant-pagination-options {
    margin-left: -84vw;
  }
  /* .home_menu_bar{
    font-size: 1vw !important;
  } */
}
@media (min-width: 2560px) {
  .ant-pagination-options {
    margin-left: -90vw;
  }
  /* .home_menu_bar{
    font-size: 18px !important;
  } */
}
@media (max-width: 768px) {
  .ant-pagination-options {
    margin-left: -69vw;
  }
  .home_menu_bar {
    font-size: var(--font-size-12) !important;
  }
}
.pagination-box {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  display: none !important;
}
.ant-menu-item-active > .ant-menu-title-content:hover {
  color: rgb(197 149 199) !important;
}
.ant-menu-sub > li {
  color: black !important;
}
.ant-menu-sub > li:hover {
  /* color: gainsboro !important; */
  color: black !important;
  font-weight: 500 !important;
  transform: scale(1) !important;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title {
  justify-content: flex-end !important;
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop {
  min-width: 190px !important;
  max-width: 300px !important;
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-bottomLeft {
  width: auto !important;
  max-width: 400px !important;
}
.about-model {
  width: 850px !important;
  height: 200px !important;
}
.ant-modal-content {
  border-radius: 20px !important;
  padding: 30px !important;
}
.select-label {
  margin-top: 5px !important;
  font-size: 13px;
  background-color: white;
  position: absolute;
  z-index: 1;
  margin-left: 10px;
}
.antd-select:hover .select-label {
  background-color: yellow !important;
}
.about_us_slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.each-slide-effect {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}
.ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
  flex-direction: row-reverse !important;
  gap: 2px !important;
}
.ant-menu-submenu-active > .ant-menu-submenu-title {
  width: 100% !important;
  justify-content: start !important;
}
.website {
  text-decoration: none;
  color: black;
}
.website:hover {
  color: #1890ff;
  text-decoration: underline;
}
/* .home_about_us_slider {
  height: 40rem;
} */
@media only screen and (max-width: 768px) {
  .head-office-logo {
    width: 100%;
  }
  .contact_us_card_image {
    width: 25vh;
    height: 25vh;
  }
  /* .home_menu_bar{
    font-size: 12px !important;
  } */
}
.contactUs-card {
  max-height: 1500px;
}
.high-cash-name {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.application-form-title {
  color: #2f80ed;
  font-weight: 500;
  cursor: pointer;
}
.dummy {
}
.carousel-height {
  /* padding-top: 30px; */
  /* padding-bottom: 30px; */
}
.ant-menu-submenu > .ant-menu {
  border-radius: 17px !important;
  /* max-width: 500px !important; */
  /* width: 350px; */
}
.ant-menu-light .ant-menu-submenu-title:hover {
  color: rgb(197 149 199) !important;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active {
  color: rgb(197 149 199) !important;
}
#rc-menu-uuid-91845-3-dept_of_youth_welfare-popup > .ant-menu-title-content,
.ant-menu-submenu-arrow {
  color: #4b1f78 !important;
}
.imhad {
  object-fit: cover;
  height: inherit;
}
.ant-menu-item:active {
  background: transparent !important;
  color: #c595c7 !important;
}
.new-tag {
  width: 50px;
  position: absolute;
  filter: invert(1);
}
.vigilince_text {
  font-size: var(--font-size-16);
  text-align: center;
  font-weight: bold;
  font-family: "Inter";
  background: var(--home-liner-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.gallery_slider .react-multiple-carousel__arrow--right::before {
  content: "\e825" !important;
  border-radius: 2px;
  content: "\e825" !important;
  color: #3b1b7e;
  width: 59px;
  height: 74px;
  display: flex;
  align-items: center;
  transform: skew(-6deg);
  justify-content: center;
  background-color: white;
  font-size: 45px;
}
.gallery_slider .react-multiple-carousel__arrow:hover {
  background: transparent !important;
}

.gallery_slider .react-multiple-carousel__arrow {
  background: transparent !important;
}
table {
  /* background-color: red !important; */
}

.executive-card {
  width: 370px !important;
  height: 250px !important;
  border-radius: 10px !important;
}
.policy-note-card {
  width: 100% !important;
  height: 150px !important;
  border-radius: 10px !important;
}

.menu-loading {
  width: 200px !important;
}
.menu-button {
  width: 200px !important;
}

.side-header {
  margin-top: var(--side-bar-logo);
  display: var(--side-bar-logo-display);
}

.hostel-title:hover {
  color: #772691;
}
.layout-children {
  min-height: calc(118vh - 522px);
  /* height: 300px; */
  overflow: hidden;
}

.leap-frog {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: var(--uib-size);
}

.leap-frog__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.leap-frog__dot::before {
  content: "";
  display: block;
  height: calc(var(--uib-size) * 0.22);
  width: calc(var(--uib-size) * 0.22);
  border-radius: 50%;
  background-color: var(--uib-color);
  will-change: transform;
}

.leap-frog__dot:nth-child(1) {
  animation: leapFrog var(--uib-speed) ease infinite;
}

.leap-frog__dot:nth-child(2) {
  transform: translateX(calc(var(--uib-size) * 0.4));
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -1.5)
    infinite;
}

.leap-frog__dot:nth-child(3) {
  transform: translateX(calc(var(--uib-size) * 0.8)) rotate(0deg);
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -3) infinite;
}

@keyframes leapFrog {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  33.333% {
    transform: translateX(0) rotate(180deg);
  }

  66.666% {
    transform: translateX(calc(var(--uib-size) * -0.4)) rotate(180deg);
  }

  99.999% {
    transform: translateX(calc(var(--uib-size) * -0.8)) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}
.gallery-carousel {
  align-items: center;
}

.go3514403264,
.go2133921798 {
  font-size: 10px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.gallery_main_image_size {
  border-radius: 20px;
  width: 100% !important;
  height: 30vw !important;
}

.gallery_other_image_size {
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  height: 14vw !important;
}

.gallery_other_image_space {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.gallery_row_space {
  margin-bottom: 25px;
}

.gallery_mobile_main_image_size {
  border-radius: 20px;
  width: 100% !important;
}

.gallery_mobile_other_image_size {
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  height: 28vw !important;
}

.gallery_mobile_other_image_space {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.gallery_mobile_row_space {
  margin-bottom: 25px;
  margin-top: 25px;
}
