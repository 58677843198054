.swiper {
  width: 150vh;
  /* transform: skew(-7deg); */
  overflow-y: hidden;
  /* height: 80vh; */
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.arrow-left {
  position: absolute;
  top: 50%;
  left: 0;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.div-margin {
  padding-left: 10px;
}
.swiper-button-prev {
  display: none;
}
.swiper-button-next {
  right: 28rem;
  color: #3b1b7e;
  background: white;
  width: 50px;
  height: 80px;
  transform: skew(-6deg);
  box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.11);
}
@media (min-width: 375px) {
  .swiper {
    width: 700px;
  }
  .swiper-button-next {
    right:23rem ;
  }
}
@media (min-width: 768px) {
  .section-with-carousel .swiper-slide img {
    width:""
  }
  .swiper-button-next {
    right: 20rem;
  }
}
 /* @media (min-width: 768px) {
  .section-with-carousel .swiper-slide img {
    height: 370px;
  }
} */
/* @media (max-width: 1440px) {
  .swiper {
    width: 1000px;
  }
  .swiper-button-next {
    right: 25rem;
  }
} */

@media (min-width: 1024px) {
  .swiper-button-next {
    right: 23rem;
  }
  .swiper {
    width: 74vw;
  }
}
@media (min-width: 1440px) {
  .swiper-button-next {
    right: 23rem;
  }
  .swiper {
    width: 64vw;
  }
}
@media (min-width: 1813px) {
  .swiper-button-next {
    right: 23rem;
  }
  .swiper {
    width: 51vw;
  }
}

/* @media (min-width: 2560px) {
  .swiper {
    height: auto;
    width: 1500px;
  }
}  */
