.delete-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e8e8e8;
    background-color: #212121;
    width: 10px;
    height: 10px;
    font-size: 24px;
    text-transform: uppercase;
    border: 2px solid #212121;
    border-radius: 10px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.15),
      2px 2px 2px rgba(0, 0, 0, 0.1),
      -3px -3px 2px rgba(255, 255, 255, 0.05),
      -2px -2px 1px rgba(255, 255, 255, 0.05);
    overflow: hidden;
    cursor: pointer;
  }
  
  .delete-button .span {
    position: relative;
    z-index: 2;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .delete-button::before {
    content: "";
    position: absolute;
    background-color: #e8e8e8;
    width: 100%;
    height: 100%;
    left: 0%;
    bottom: 0%;
    transform: translate(-100%, 100%);
    border-radius: 10px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .delete-button:hover::before {
    transform: translate(0,0);
    transition-delay: 0.4s;
  }
  
  .delete-button:hover .span {
    scale: 1.2;
  }
  
  .delete-button:hover {
    box-shadow: none;
  }
  
  .delete-button:active {
    scale: 0.95;
  }
  